import "./workingwithme.css";

import vid from "../images/contactvideo.mp4";
import { Link } from "react-router-dom";

function WorkingWithMe() {
  return (
    <div className="working-with-me-container">
      <div className="working-with-me">
        <div className="col">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="hero-vid"
            preload="none"
          >
            <source src={vid} type="video/mp4" />
          </video>
        </div>

        <div className="col">
          <div className="header">
            <svg
              id="uuid-411ffa35-ce6a-4b5f-ab51-66579885e3ef"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 733.3 138.91"
            >
              <path
                d="m17.29,11.92c-4.81,20.91-8.81,42-11.91,63.23-1.55,10.58-2.92,21.2-4.08,31.83-1.05,9.7-4.79,25.35,8.29,28.62,4.99,1.25,9.06-.96,11.94-4.86l.42-.57c3.4-4.59.76-11.81-3.79-14.47-5.38-3.15-11.06-.81-14.47,3.79l-.42.57,11.94-4.86-.66-.17,4.67,2.72c1.51,3.54,2.2,3.83,2.06.87.12-1.29.25-2.59.37-3.88.19-1.94.39-3.88.6-5.82.48-4.53,1-9.05,1.56-13.56,1.04-8.42,2.25-16.81,3.6-25.18,2.83-17.65,6.28-35.2,10.29-52.63,3.05-13.25-17.34-18.91-20.4-5.62h0Z"
                stroke-width="0"
              />
              <path
                d="m27.89,91.58c13.74-2.65,26.9-7.37,39.37-13.71,5.09-2.59,6.56-9.75,3.79-14.47-3.03-5.17-9.36-6.39-14.47-3.79-10.86,5.53-22.35,9.27-34.31,11.58-5.61,1.08-8.82,7.81-7.39,13.01,1.61,5.87,7.38,8.47,13.01,7.39h0Z"
                stroke-width="0"
              />
              <path
                d="m62.96.47c-9.6,3.31-13.13,12.3-12.45,21.87.79,11.05,2.5,22.14,4.29,33.07,3.51,21.34,8.6,42.39,15.11,63.01,4.09,12.93,24.51,7.41,20.4-5.62-5.36-16.98-9.78-34.24-13.11-51.74-1.61-8.44-3.01-16.92-4.08-25.44-.55-4.41-1.16-8.84-1.46-13.27-.08-1.21-.09-2.4-.08-3.61-.16.78-.1.53.2-.74-.44,1.42-1.5,2.38-3.2,2.88,5.4-1.86,8.97-7.25,7.39-13.01-1.44-5.25-7.59-9.26-13.01-7.39h0Z"
                stroke-width="0"
              />
              <path
                d="m120.05,39.71c2.26-8.39-3.26-16.15-11.5-17.9-9.07-1.93-16,5.06-19.26,12.74-6,14.17-6.74,30.78-1.77,45.39,4.67,13.73,14.26,27.68,29.01,31.63,13.91,3.73,30.8-4.34,33.57-19.24,1.74-9.36-2.97-18.19-7.83-25.86s-10.6-14.55-17.16-20.66c-9.95-9.27-24.94,5.65-14.96,14.96,7.3,6.8,15.28,15.58,18.7,25.17,1.04,2.91.65,3.71-2.52,5.07-2.23.96-5.41.17-8.12-1.69-7.27-5-11.05-14.76-12.39-23.15-.75-4.72-.7-9.86.23-14.55.52-2.65,1.2-5.37,2.21-7.89.26-.63.8-1.46.97-2.1-.13.52-3.56.96-.68.57h-5.62c2.3.49,1.61-.16-2.07-1.95l-1.44-5.34.24-.83c-3.55,13.16,16.85,18.77,20.4,5.62h0Z"
                stroke-width="0"
              />
              <path
                d="m155.95,35.8c-.31,22.61.02,46.65,11.57,66.84,2.88,5.04,6.37,10.05,12.03,12.1,6.55,2.37,12.99.16,18.58-3.54,4.6-3.05,8.93-6.66,12.51-10.87,1.91-2.25,3.74-4.62,5.35-7.09,1.06-1.62,2.32-4.72,3.46-5.72l-4.67,2.72,1.2-.39h-5.62c-2.32-2.05-2.79-2.12-1.42-.22.52.86,1.07,1.69,1.65,2.51,1.37,1.94,2.84,3.88,4.56,5.53,3.51,3.39,7.83,5.68,12.63,6.49,10.77,1.83,18.75-4.74,22.94-14.14,7.1-15.92,5.6-35.47-5.16-49.46-3.48-4.52-9.1-6.94-14.47-3.79-4.45,2.6-7.3,9.91-3.79,14.47,3.82,4.97,6.31,10.38,6.67,16.7.18,3.08-.08,6.04-.87,9.01-.18.66-2.7,7.62-3.23,7.22.54-.64,1.23-.85,2.06-.61-.78.11-1.32-.1-1.63-.65-.48-.48-1-.93-1.43-1.44-2.79-3.34-4.15-7.41-7.94-9.93-4.25-2.82-9.78-3.26-14.29-.62-4.06,2.37-5.76,6.55-8.08,10.35-1.7,2.8-3.69,5.22-6.03,7.51-2.2,2.15-5.77,3.62-7.7,5.79-1.64,1.85,3.26.54,3.11.87.18-.4-2.59-4.26-2.92-4.88-1.62-3.01-2.84-6.1-3.84-9.37-4.51-14.67-4.26-30.2-4.06-45.39.18-13.62-20.97-13.62-21.15,0h0Z"
                stroke-width="0"
              />
              <path
                d="m316.69,27.65c-2.09,12.59-3.66,25.28-4.51,38.02-.41,6.06-.66,12.12-.76,18.19-.05,3.19-.06,6.39-.02,9.58.01,1.28.04,2.56.06,3.83.02.96.05,1.92.08,2.87.04,1.23.04,1.28,0,.13l4.86-6.32-.74.51h10.68l-.5-.22,4.86,6.32-.09-.61v5.62l.2-.5-13.01,7.39.39.12c13.04,4.02,18.61-16.39,5.62-20.4l-.39-.12c-5.93-1.83-10.9,2.22-13.01,7.39-4.64,11.37,10.99,18.92,18.81,11.11,3.65-3.65,3.5-8.55,3.39-13.29-.09-4.15-.11-8.31-.05-12.46.23-17.27,1.7-34.5,4.52-51.54.93-5.63-1.47-11.39-7.39-13.01-5.06-1.39-12.07,1.72-13.01,7.39h0Z"
                stroke-width="0"
              />
              <path
                d="m287.13,43.34c26.17-3.84,52.22-8.17,78.54-10.89,5.68-.59,10.58-4.44,10.58-10.58,0-5.26-4.86-11.17-10.58-10.58-28.2,2.91-56.13,7.52-84.17,11.64-5.66.83-8.77,7.99-7.39,13.01,1.67,6.06,7.34,8.22,13.01,7.39h0Z"
                stroke-width="0"
              />
              <path
                d="m289.07,116.86c5.87,3.67,13.64,2.75,20.29,2.85s13.7.14,20.55.11c13.7-.06,27.39-.39,41.08-.96s13.63-21.72,0-21.15c-12.38.52-24.77.84-37.17.94-5.87.05-11.74.05-17.61,0-3.26-.03-6.52-.07-9.78-.13-1.3-.02-2.61-.05-3.91-.08-.98-.02-1.96-.04-2.93-.07-1.36-.1-1.31-.02.17.23-11.57-7.24-22.2,11.05-10.68,18.27h0Z"
                stroke-width="0"
              />
              <path
                d="m419.08,17.13c-7.49,15.14-11.03,31.49-11.58,48.33-.26,8.07.17,16.16,1.09,24.18.98,8.55,1.99,17.49,6.57,25,9.51,15.6,29.89,17.65,41.78,3.54,10.72-12.73,13.85-30.57,9.29-46.43l-20.4,5.62c2.46,6.82,5.47,13.45,9.46,19.51s8.73,13.2,15.82,15.62c14.49,4.95,26.73-10.72,31.77-22.42,11.05-25.63,2.67-55.13-15.24-75.39-3.79-4.28-11.09-3.86-14.96,0-4.25,4.25-3.79,10.67,0,14.96,10.81,12.23,16.93,29.94,12.87,46.1-.9,3.57-2.15,6.8-4.08,9.93-.77,1.26-1.59,2.4-2.57,3.49-.63.7-1.37,1.31-1.99,2.01-1.93,2.22-1.14.44-.79,1.18.6.01,1.17.15,1.72.41,1.35.62,1.58.56.69-.16-.25-.69-1.55-1.7-2.03-2.3-1.41-1.77-2.68-3.66-3.88-5.57-2.58-4.12-4.74-8.44-6.39-13.01-1.94-5.37-7.2-8.99-13.01-7.39-5.48,1.51-8.97,7.5-7.39,13.01,2.53,8.79,1.56,19.1-4.35,26.42-1.76,2.18-3.59,3.95-5.95,2.42-3.81-2.45-4.59-8.54-5.25-12.59-3.52-21.62-2.79-45.9,7.07-65.83,2.53-5.12,1.37-11.45-3.79-14.47-4.67-2.73-11.93-1.35-14.47,3.79h0Z"
                stroke-width="0"
              />
              <path
                d="m563.51,46.29c-.61-10.26-8.65-18.04-19.07-18.14-8.7-.08-17.2,4.69-23.94,9.81-12.73,9.69-22.14,24.77-21.85,41.19.27,15.6,9.73,31.41,24.07,38,7.59,3.49,16.65,4.91,24.68,2.01,9.04-3.27,14.94-11.42,18.26-20.11,7.43-19.43-1.12-39.57-13.33-54.8-3.58-4.46-11.23-3.73-14.96,0-4.41,4.41-3.59,10.49,0,14.96,7.18,8.94,12.64,21.34,8.42,32.77-1.61,4.36-4.13,8.01-9.11,7.54-4.17-.4-8.2-2.91-11.12-6.21-6.69-7.54-7.35-17.87-2.66-26.6,2.34-4.36,5.55-8.27,9.43-11.37,2.23-1.78,4.84-3.36,7.41-4.58.94-.45,3.69-.93,4.31-1.44-.11.09-1.61-1.36-1.71-3.03.34,5.7,4.62,10.58,10.58,10.58,5.46,0,10.92-4.85,10.58-10.58h0Z"
                stroke-width="0"
              />
              <path
                d="m575.81,66.03c2.44,15.44,3.42,31.07,3.28,46.7l20.78-2.81c-3.29-13.11-6.64-27.77-5.02-41.37,1.01-8.49,7.39-20.76,17.49-18.19-2.11-5.18-4.21-10.36-6.32-15.54-4.11,9.57-12.48,16.35-16.82,25.79-5.41,11.78-4.32,23.7,3.98,33.75,6.75,8.18,16.1,14.62,24.19,21.41,4.47,3.75,8.81,7.64,14.97,7.89s11.88-3.28,13.48-9.55c3.37-13.2-17.02-18.82-20.4-5.62l-.22.85,2.72-4.67.93-.75,5.34-1.44c-2.16.27.29.15.39.24-1.39-1.32-2.96-2.5-4.43-3.73-2.9-2.44-5.8-4.88-8.69-7.32-4.46-3.78-11.77-8.17-14.13-13.79-2.22-5.29,2.46-11.35,5.48-15.33,4.18-5.5,8.72-10.64,11.48-17.05,2.6-6.05.95-13.68-6.32-15.54-18.99-4.84-36.2,8.71-41.94,26.26-6.09,18.6-1.17,40.92,3.45,59.32,2.93,11.68,20.66,9.05,20.78-2.81.17-17.55-1.29-34.99-4.03-52.32-.89-5.65-7.95-8.78-13.01-7.39-6.02,1.65-8.28,7.35-7.39,13.01h0Z"
                stroke-width="0"
              />
              <path
                d="m651.84,30.37c-5.63,32.35-7.95,65.13-6.57,97.94.37,8.78,11.51,14.43,18.06,7.48,1.09-1.16,2.18-2.32,3.27-3.48,9.32-9.91-5.61-24.9-14.96-14.96-1.09,1.16-2.18,2.32-3.27,3.48,6.02,2.49,12.04,4.99,18.06,7.48-1.3-30.89.52-61.85,5.81-92.32.98-5.62-1.51-11.39-7.39-13.01-5.1-1.4-12.03,1.73-13.01,7.39h0Z"
                stroke-width="0"
              />
              <path
                d="m656.61,91.13c12.75,6.76,22.27-3.93,30.64-12.23,8.95-8.88,17.9-17.75,26.85-26.63-5.54-4.27-11.07-8.54-16.61-12.82-10.44,17.58-23.87,32.82-39.82,45.62-9.5,7.63,2.69,22.58,12.82,16.61,2.46-1.45,5.93.27,8.41,1.56,4.69,2.42,9.16,5.36,13.46,8.41,8.51,6.04,16.1,13.31,22.96,21.15,3.76,4.3,11.11,3.85,14.96,0,4.27-4.27,3.77-10.65,0-14.96-9.18-10.5-19.8-19.68-31.45-27.34-11.44-7.53-25.79-14.9-39.01-7.09,4.27,5.54,8.54,11.07,12.82,16.61,17.26-13.86,31.82-30.87,43.12-49.9,6.15-10.36-8.07-21.29-16.61-12.82-6.59,6.54-13.19,13.08-19.78,19.62-3.3,3.27-6.59,6.54-9.89,9.81-1.4,1.39-2.73,2.94-4.24,4.21-.37.41-.77.78-1.21,1.1-2.61,1.23-1.52,1.5,3.27.81-12.02-6.37-22.72,11.88-10.68,18.27h0Z"
                stroke-width="0"
              />
            </svg>
          </div>
          <p>
            The first step of every project is getting to know you and your
            goals. If you're local, we’ll meet over coffee; if not, we can
            connect via Zoom or a phone call to discuss your vision. Once you've
            decided I'm your gal, the real work begins. This might involve
            additional meetings, setting clear objectives, exchanging logins and
            passwords, setting up analytics, or providing access to the code
            base. From there, we dive straight into media gathering,
            copywriting, and design, bringing your project to life.
          </p>
          <h3>Coffee & Getting to Know Eachother</h3>
          <p>
            Once the design work is complete—whether it's designing campaigns or
            other assets—we move on to the approval phase. You'll review the
            project and give it your blessing—or, if you're not completely
            satisfied, we’ll head back to the drawing board. After that, it's
            time to launch the code, kick off campaigns, or deliver the final
            product.
          </p>
          <h3>
            The creative process is a process and every time it’s a slightly
            different one.
          </h3>
          <p>
            For web projects, we conduct in-depth user testing to ensure the
            site meets your expectations and more. After a thorough review, we
            make sure it's everything you wanted and beyond. Then comes the site
            launch, and baby, we're live!
          </p>
          <p>
            For campaigns, we wrap things up and discuss next steps. Do you or
            your team want to be trained to manage your marketing, or would you
            prefer to retain me for ongoing support? This is also the time we
            dive into the data and focus on what's been most successful for you.
          </p>
          <Link to="/contact">
            {" "}
            <button>Ready to Start?</button>{" "}
          </Link>{" "}
          <Link to="/aboutme">
            <button>Find Out More</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default WorkingWithMe;
