import "./campaign.css";

import { Link } from "react-router-dom";

export default function Campaign() {
  return (
    <div className="campaign">
      <div className="lets-campaign">
        <svg
          id="uuid-b0f5c1ab-4767-4d01-8eac-972d5eda8dda"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1335.64 160.66"
        >
          <path
            d="m15.93,30.55C8.83,65.27,3.6,100.39.27,135.67c-.95,10.01.08,20.82,11.58,24.28,4.98,1.5,10.08.27,15.05-.56,4.49-.75,8.97-1.51,13.45-2.29,17.28-3.01,34.52-6.3,51.7-9.84,7.3-1.5,11.52-10.13,9.64-16.98-2.09-7.59-9.65-11.15-16.98-9.64-11.77,2.42-23.57,4.73-35.39,6.91-5.75,1.06-11.51,2.09-17.27,3.09-2.88.5-5.76.99-8.65,1.48-1.28.22-2.57.42-3.85.64-3.93.69-.31-.12,1.88,1.28l4.95,4.95c.61,4.09.93,4.9.95,2.44.11-1.29.23-2.57.35-3.86.18-1.93.36-3.86.55-5.78.44-4.5.92-8.99,1.43-13.48.99-8.72,2.09-17.41,3.35-26.09,2.65-18.2,5.84-36.32,9.52-54.35,1.49-7.3-2.11-14.91-9.64-16.98-6.82-1.87-15.48,2.3-16.98,9.64h0Z"
            stroke-width="0"
          />
          <path
            d="m129.16,34.69c-7.87,29.73-12.53,60.3-14,91.02-.36,7.45,6.6,13.8,13.8,13.8,7.77,0,13.44-6.33,13.8-13.8,1.36-28.34,5.75-56.26,13.01-83.68,1.91-7.2-2.4-14.99-9.64-16.98s-15.06,2.41-16.98,9.64h0Z"
            stroke-width="0"
          />
          <path
            d="m135.67,57.14c17.88,4.37,36.49,4.11,54.16-1.14,7.14-2.12,11.64-9.69,9.64-16.98s-9.8-11.77-16.98-9.64c-13.03,3.87-26.33,4.35-39.48,1.14-7.23-1.77-14.96,2.31-16.98,9.64s2.37,15.2,9.64,16.98h0Z"
            stroke-width="0"
          />
          <path
            d="m141.65,108.92c11.35.27,22.66-.28,33.93-1.66,7.4-.91,13.8-5.69,13.8-13.8,0-6.76-6.35-14.71-13.8-13.8-11.27,1.38-22.58,1.93-33.93,1.66-7.46-.18-13.8,6.47-13.8,13.8s6.32,13.62,13.8,13.8h0Z"
            stroke-width="0"
          />
          <path
            d="m129.37,147.93c21.14,7.45,43.75,5.49,64.01-3.55,6.81-3.04,8.42-12.95,4.95-18.88-4.1-7.01-12.05-8-18.88-4.95-13.49,6.02-28.59,5.75-42.74.76-7.03-2.48-15.1,2.81-16.98,9.64-2.09,7.62,2.58,14.49,9.64,16.98h0Z"
            stroke-width="0"
          />
          <path
            d="m237.09,30.88c-4.88,32.13-7.8,64.47-8.38,96.97-.13,7.46,6.44,13.8,13.8,13.8s13.67-6.32,13.8-13.8c.53-30,2.89-59.97,7.4-89.63,1.12-7.36-1.86-14.84-9.64-16.98-6.53-1.79-15.85,2.23-16.98,9.64h0Z"
            stroke-width="0"
          />
          <path
            d="m210.22,41.53c24.6,14.2,54.15,18.04,81.56,10.59,7.19-1.96,11.61-9.81,9.64-16.98s-9.76-11.6-16.98-9.64c-20.24,5.5-41.79,2.88-60.3-7.8-6.45-3.73-15.11-1.5-18.88,4.95s-1.52,15.14,4.95,18.88h0Z"
            stroke-width="0"
          />
          <path
            d="m331.12,23.11c-17.3,10.07-31.71,24.51-41.74,41.84-4.09,7.06-.99,14.31,4.95,18.88,8.15,6.27,18.54,8.74,28.34,11.14,2.41.59,4.82,1.18,7.2,1.87.96.28,1.92.58,2.88.9.4.13,3.79,1.49,2.19.77-1.7-.77,1.13.61,1.45.78.98.54,1.93,1.13,2.84,1.77-2.57-1.8.13.36.55.74,2.73,2.45-1.88-2.01.58.83,1.57,1.81-.33-1.18.65,1.42.91,2.43-.08-.7.18.79.04.65.04,1.29,0,1.94.24-1.3.27-1.44.07-.44-.22,1.08-.52,2.14-.86,3.19.75-2.31-.54,1.08-.84,1.6-.05.09-2.39,3.41-.98,1.68-.87,1.07-1.87,2.01-2.86,2.97-1.79,1.73.8-.1-1.57,1.13-9.63,5-20.53,3.84-31.44,1.57-7.29-1.52-14.91,2.13-16.98,9.64-1.88,6.85,2.31,15.45,9.64,16.98,17.29,3.61,36.93,5.35,52.71-4.35,13.96-8.58,22.97-25.05,20.05-41.49-2.64-14.88-15.11-23.89-28.62-28.31-5.52-1.8-11.24-2.91-16.84-4.41-2.8-.75-5.59-1.58-8.29-2.65,2.82,1.11-1.67-.91-2.17-1.2-1.39-.8-2.69-1.71-3.95-2.68l4.95,18.88c7.81-13.49,18.44-24.13,31.84-31.93,6.44-3.75,8.71-12.46,4.95-18.88s-12.42-8.72-18.88-4.95h0Z"
            stroke-width="0"
          />
          <path
            d="m578.24,40.74c9.18-13.21-2.91-28.63-14.58-34.9-10.12-5.44-21.56-6.77-32.82-5.24-23.07,3.15-43.05,18.65-54.4,38.7-11.38,20.09-14.22,44.79-5.69,66.46s26.98,40.24,50.56,46.89c13.95,3.93,29.35,4.44,43.72,2.99s27.62-5.86,36.78-18.17c4.46-5.98.98-15.41-4.95-18.88-7.04-4.12-14.42-1.05-18.88,4.95,1.34-1.8-3.08,2.09-.97.74-.48.31-4.8,2.29-2.94,1.64-1.81.63-3.65,1.13-5.52,1.53-.95.2-1.9.37-2.86.53,1.88-.3-.7.06-1.38.13-8.78.8-18.53,1.03-26.92-.18-9.04-1.3-16.24-3.86-23.47-8.84-2-1.38-4.51-3.6-7.16-6.43-1.16-1.24-2.32-2.52-3.36-3.88,1.03,1.35-.39-.62-.47-.74-.81-1.23-1.63-2.44-2.39-3.7-3.57-5.95-6.2-12.98-7-19.67-.84-7.02-.07-13.74,2.17-21.01,1.34-4.35,1.86-5.47,3.83-9.12.83-1.54,1.73-3.05,2.67-4.52.54-.84,1.1-1.67,1.67-2.49.26-.37,2.02-2.64,1.02-1.4,5.51-6.79,10.17-10.82,17.63-14.63.61-.31,1.24-.61,1.86-.9.87-.4.84-.39-.11.03l1.3-.49c2.21-.73,4.4-1.43,6.68-1.94.9-.2,1.81-.37,2.72-.53-1.96.34.27,0,1.12-.07,2.05-.14,4.06-.05,6.11-.02,2.67.04,1,.08.31-.03,1.3.21,2.6.5,3.86.85.63.18,1.25.4,1.87.59,1.36.41-1.2-.82.9.37.74.42,1.51.88,2.27,1.28,2.72,1.42-.81-1.19,1.39,1.01.45.45,1.44,1.68,1.9,1.9-2.05-.99.48-7.88-.31-6.74-4.25,6.12-1.13,15.33,4.95,18.88,6.87,4.02,14.62,1.19,18.88-4.95h0Z"
            stroke-width="0"
          />
          <path
            d="m635.66,47.17c-8.2,27.35-12.88,55.71-14.13,84.23-.3,6.78,5.47,12.78,11.97,13.55s13.96-3.03,15.14-9.88c2.93-17.01,6.66-33.88,11.28-50.52,2.22-8.01,4.64-15.96,7.25-23.85,1.4-4.23,2.71-8.56,4.39-12.69,1.56-3.84.27-.35.01-.43-1.77,1.67-3.66,2.27-5.69,1.8-.8-.47-.78-.47.08.01,3.22,2.94,9.05,8.44,11.9,12.42,4.04,5.63,7.51,11.65,10.6,17.86,6,12.06,10.47,25.46,14.97,39.01,2.35,7.07,9.52,11.69,16.98,9.64,6.91-1.9,12-9.87,9.64-16.98-5.64-16.97-11.48-34.08-19.96-49.89-4.34-8.08-9.52-15.64-15.42-22.66s-12.97-13.98-22.13-16.16c-13.02-3.11-22.6,5.13-27.07,16.62-3.65,9.39-6.64,19.09-9.51,28.74-5.84,19.61-10.46,39.57-13.93,59.74l27.11,3.67c1.15-26.15,5.62-51.82,13.14-76.89,2.14-7.14-2.57-15.03-9.64-16.98s-14.83,2.47-16.98,9.64h0Z"
            stroke-width="0"
          />
          <path
            d="m640.38,98.64c19.05,2.47,38.2,1.36,56.98-2.59,7.3-1.54,11.53-10.11,9.64-16.98-2.08-7.56-9.66-11.18-16.98-9.64-16.37,3.44-33.07,3.75-49.65,1.6-7.4-.96-13.8,7.03-13.8,13.8,0,8.25,6.37,12.84,13.8,13.8h0Z"
            stroke-width="0"
          />
          <path
            d="m724.2,55.7c-.35,23.41,1.62,46.71,5.47,69.8,1.11,6.68,8.99,10.81,15.14,9.88,6.9-1.04,11.68-6.53,11.97-13.55.84-20.91,1.61-41.96,6.85-62.32.31-1.21.63-2.41.98-3.61.04-.16.7-3,.42-1.7-.12.56.65-.39.05-.33-1.32,1.67-3.23,2.5-5.74,2.49l-3.6-.84c-.25-.08-1.47-.78-1.38-1.15-.08.34,1.18,2.06,1.38,2.43-.87-1.64.17.39.41,1,.59,1.51,1.18,3.02,1.77,4.53,4.13,10.56,8.25,21.13,12.38,31.69,2.25,5.75,6.78,10.13,13.31,10.13,5.89,0,11.94-4.26,13.31-10.13,2.82-12.08,6.56-24.03,11.74-35.31,1.39-3.02,2.85-6.02,4.39-8.97.56-1.06,1.66-2.37,1.92-3.5.33-1.38.19,0-.66.28,1.15-.36-3.96,1.84-4.3.65,0,.02,3.35,2.07,2.05,1.11.82.61,2.49,2.53,2.9,3.19,1.24,2.03,2.39,4.07,3.41,6.22,1.83,3.86-.57-2.03.79,1.99.4,1.19.85,2.37,1.25,3.56,2.12,6.28,3.94,12.66,5.56,19.08,3.4,13.48,5.9,27.17,8.41,40.84,1.34,7.34,10.24,11.49,16.98,9.64,7.71-2.12,10.99-9.62,9.64-16.98-3.07-16.77-6.14-33.58-10.87-49.98-3.91-13.58-8.56-28.87-19.91-38.19-6.97-5.72-16.17-9.56-25.24-6.93s-13.54,10.61-17.39,18.32c-7.56,15.15-13.41,31.15-17.26,47.64h26.61c-5.52-14.13-10.36-28.8-16.75-42.56-2.91-6.27-7.99-12.32-14.83-14.38-8.26-2.49-16.79.55-21.96,7.35-4.11,5.4-5.71,12.22-7.23,18.73-1.77,7.58-2.94,15.27-3.93,22.99-1.83,14.28-2.44,28.67-3.02,43.04,9.04-1.22,18.07-2.45,27.11-3.67-3.43-20.64-4.79-41.55-4.48-62.46.11-7.46-6.42-13.8-13.8-13.8s-13.69,6.32-13.8,13.8h0Z"
            stroke-width="0"
          />
          <path
            d="m863.84,70.55c.3,22.42,2.74,44.77,7.07,66.77l26.61-7.34c-5.58-18-10.15-36.93-12.03-55.54-.84-8.29-1.23-16.67,1.03-24.58,1.15-4.03,1.73-5.06,4.15-8.95.74-1.19,3.96-4.38,4.86-4.96,2.8-1.8,4.52-2.94,7.6-2.17-2.25-.56,2,1.13-.15.08,1.09,1.21,1.28,1.33.58.35.54.77,1.03,1.58,1.47,2.41.75,1.49.84,1.7.29.64.31.9.57,1.82.79,2.74.88,2.61.22-1.1.34,1.77.08,1.81.09,3.59-.04,5.4.19-2.66-.52,2.38-.68,2.97-.13.5-1.87,5.22-.92,3.07-.73,1.66-1.63,3.26-2.59,4.8-.15.25-2.39,3.41-1.02,1.65-1.11,1.43-2.39,2.76-3.7,4.01-.66.63-1.33,1.23-2.03,1.82,1.02-.75.87-.64-.45.3-1.53.98-3.06,1.88-4.67,2.74-1.49.7-1.63.77-.42.21-.85.31-1.7.59-2.57.83-.57.16-5.61.94-3.01.69-7.42.71-13.8,5.83-13.8,13.8,0,6.91,6.34,14.52,13.8,13.8,17.63-1.69,32.87-11.24,42.06-26.5s10.95-34.84,1.74-49.9c-12.14-19.88-37.59-18.54-53.44-4.04-14.36,13.14-18.47,33.82-17.23,52.46,1.52,22.78,6.66,45.66,13.41,67.43,2.2,7.12,9.63,11.66,16.98,9.64s11.08-9.64,9.64-16.98c-3.86-19.62-5.81-39.44-6.08-59.43-.1-7.46-6.27-13.8-13.8-13.8s-13.9,6.32-13.8,13.8h0Z"
            stroke-width="0"
          />
          <path
            d="m935.04,32.3c-10.19,28.39-16.84,57.63-20.7,87.53-.87,6.71,5.91,12.83,11.97,13.55s13.92-3.04,15.14-9.88c5.49-30.71,14.36-60.92,27.01-89.45l-18.88,4.95c-.78-.78-.56-.6.65.52.7.66,1.36,1.36,1.98,2.1-1.28-1.63,1.16,1.99,1.16,2,2.07,3.57,2.08,3.88,3.45,7.64,2.83,7.78,5.46,15.64,8.19,23.45,5.89,16.9,11.77,33.79,17.66,50.69,2.45,7.04,9.45,11.71,16.98,9.64,6.83-1.88,12.1-9.91,9.64-16.98-6.54-18.77-13.08-37.55-19.62-56.32-5.94-17.05-10.35-35.86-26.15-46.57-5.69-3.86-15.99-1.56-18.88,4.95-13.66,30.81-23.86,62.85-29.8,96.05l27.11,3.67c3.53-27.33,10.41-54.28,19.71-80.19,2.52-7.02-2.84-15.11-9.64-16.98-7.65-2.1-14.45,2.6-16.98,9.64h0Z"
            stroke-width="0"
          />
          <path
            d="m938.38,103.38c16.64-5.17,33.28-10.35,49.91-15.52,7.12-2.21,11.66-9.62,9.64-16.98s-9.83-11.86-16.98-9.64c-16.64,5.17-33.28,10.35-49.91,15.52-7.12,2.21-11.66,9.62-9.64,16.98s9.83,11.86,16.98,9.64h0Z"
            stroke-width="0"
          />
          <path
            d="m1051.17,15.43c-.07,16.17-.13,32.34-.2,48.51-.03,7.92-.07,15.84-.1,23.76-.02,3.96-.03,7.92-.05,11.88l-.02,5.94v2.97c-.19,3.28.14,2.66,1-1.87l4.95-4.95-.87.49,10.63-1.39-.58-.1,8.25,6.34.95,2.2v7.34l.1-.57-3.55,6.09.29-.31-13.43,3.55.47.16c7.03,2.47,15.1-2.8,16.98-9.64,2.09-7.61-2.58-14.5-9.64-16.98l-.47-.16c-4.47-1.57-10.23.19-13.43,3.55-4.87,5.12-5.73,12.61-2.11,18.7,4.86,8.19,16.57,8.96,23.12,2.41,5.07-5.07,4.91-12.18,4.93-18.82.02-5.94.05-11.88.07-17.82.1-23.76.2-47.52.29-71.28.03-7.46-6.36-13.8-13.8-13.8s-13.77,6.32-13.8,13.8h0Z"
            stroke-width="0"
          />
          <path
            d="m1029.25,32.52c-5.06,6.23-6.17,8.47-3.35,6.73.84-.46.76-.44-.25.06,1.59-.56,3.32-.91,4.96-1.31,3.41-.84,6.85-1.61,10.29-2.31,6.62-1.35,13.29-2.46,20.01-3.19,14.63-1.59,29.36-1.87,44.04-1,7.45.44,13.8-6.66,13.8-13.8,0-7.84-6.33-13.36-13.8-13.8-19.18-1.13-38.23-.07-57.22,2.71-8.85,1.29-17.66,2.99-26.32,5.25-10.54,2.75-19.21,8.87-19.77,20.67-.35,7.45,6.59,13.8,13.8,13.8,7.77,0,13.45-6.33,13.8-13.8h0Z"
            stroke-width="0"
          />
          <path
            d="m1024.42,130.51c16.39,1.64,32.77,3.28,49.16,4.91,6.73.67,13.6,1.73,20.38,1.27,9.17-.63,16.5-3.34,23.74-9,5.88-4.59,4.82-14.7,0-19.52-5.8-5.8-13.62-4.6-19.52,0,1.44-1.12-2.39.79-.42.05-.35.13-3.6.94-2.58.75-1.27.23-5.16.24-7.5.13-6.68-.31-13.35-1.21-20-1.87-14.42-1.44-28.84-2.88-43.26-4.32-7.43-.74-13.8,6.87-13.8,13.8,0,8.08,6.35,13.06,13.8,13.8h0Z"
            stroke-width="0"
          />
          <path
            d="m1188.53,39.25c5.47-9.76,3.47-22.02-4.56-29.84-8.26-8.04-20.52-9.7-31.18-5.82-18.72,6.82-31.7,23.57-38.65,41.64-6.43,16.71-7.3,37.56-.54,54.29,7.87,19.47,24.95,29.62,45.35,31.41,9.77.86,19.98-.51,29.45-2.95,6.38-1.64,12.7-4.74,16.02-10.76,4-7.25,2.84-16.37-3.11-22.13-5.35-5.18-14.19-5.33-19.52,0s-5.37,14.31,0,19.52c-3.38-7.01-2.93-11.62,1.37-13.81-.92.27-1.86.52-2.79.74-1.86.46-3.74.86-5.62,1.2-.44.08-4.41.61-2.08.35-1.93.22-3.87.36-5.8.43-1.44.05-2.89.06-4.34.02-.5-.01-5.8-.45-3.43-.13-3.16-.42-6.42-1.24-9.38-2.43,2.15.87-2.35-1.33-2.8-1.61-.31-.19-3.2-2.52-1.59-1.08-2.85-2.54-4.15-4.12-5.87-7.98.76,1.71-.94-2.79-.86-2.54-.62-2.01-1.08-4.06-1.47-6.13-.47-2.55-.19-.18-.29-2.58-.09-1.95-.21-3.88-.2-5.83.1-11.47,4.26-25.16,11.59-33.17,2.27-2.48,4.56-4.98,7.26-7-1.3.98,2.27-1.41,2.78-1.71,1.63-.96,1.93-1.01,3.38-1.66.52-.23,3-.84,2.08-.78,1.71.5,2.05.55,1.04.15-.88-.41-.7-.26.55.43-.69-.27-1.17-.74-1.45-1.43.78,1.41.87,1.4.25-.02.41,1.6.42,1.54.05-.18-.02-.88.15-1.72.51-2.52-3.65,6.5-1.56,15.07,4.95,18.88s15.22,1.57,18.88-4.95h0Z"
            stroke-width="0"
          />
          <path
            d="m1159.66,101.04c10.6-1.61,21.2-3.22,31.81-4.83,1.88-.28,4.31-.71,5.15-.77,2.97-.22,3.45,1-1.61-2.97-2.32-.5-3.58-1.63-3.79-3.39-.1-.71-.13-.64-.09.21-.63,2.08-.06,5.32-.08,7.51-.04,2.92-.07,5.85-.11,8.77-.06,5.2-.13,10.4-.19,15.6-.07,6,.23,12.24,4.77,16.77,5.72,5.72,15.35,6.12,21.15.26,4.54-4.59,6.17-12.97,2.28-18.51-2.61-3.72-6.95-7.39-11.92-6.83l-.51.06c-7.41.82-13.8,5.75-13.8,13.8,0,6.82,6.34,14.63,13.8,13.8l.51-.06-11.92-6.83.26.37c-.63-2.32-1.26-4.64-1.88-6.97l.32-2.47,3.55-6.09-.4.43,9.76-4.04-.6.06,6.97,1.88-.84-.54,4.95,4.95c.9,4.53,1.28,5.16,1.13,1.9.02-1.3.03-2.6.05-3.9.03-2.27.06-4.55.08-6.82.06-4.87.12-9.75.18-14.62.12-10.13.61-20.93-9.21-27.02-8.5-5.28-19.06-2.1-28.19-.71-9.64,1.46-19.28,2.93-28.91,4.39-7.37,1.12-11.45,10.4-9.64,16.98,2.17,7.89,9.58,10.76,16.98,9.64h0Z"
            stroke-width="0"
          />
          <path
            d="m1227.58,17.38c-2.9,35.14-4.54,70.37-4.92,105.64-.17,15.56,23.02,18.74,27.11,3.67,9.03-33.35,3.08-68.66,12.26-101.75l-25.22,3.3c6.39,14.97,13.25,29.73,20.63,44.23,7.15,14.04,14.32,28.61,23.15,41.68,9.3,13.77,26.15,24.02,42.46,14.87s12.38-30.68,11.38-46.29c-1.26-19.62-2.52-39.25-3.78-58.87-.48-7.44-6-13.8-13.8-13.8-7.1,0-14.28,6.32-13.8,13.8,1.2,18.63,2.39,37.25,3.59,55.88.55,8.48,1.95,17.43,1.41,25.92.06-1.02.39.65-.43,1.28.66-1.38,1.67-2.15,3.02-2.31l-1.27.37c1.46-.15,1.35-.2-.35-.15,1.39.21,1.26.13-.39-.25,1.69,1.08-.72-.49-.78-.53-4.9-3.95-8.42-12.05-11.92-18.24-4.31-7.63-8.48-15.33-12.51-23.12-8.2-15.84-15.77-32-22.78-48.4-2.09-4.91-8.97-7.31-13.75-6.59-5.38.81-9.98,4.5-11.47,9.88-9.24,33.31-3.28,68.63-12.26,101.75,9.04,1.22,18.07,2.45,27.11,3.67.38-35.26,2.02-70.49,4.92-105.64.61-7.44-6.78-13.8-13.8-13.8-7.98,0-13.18,6.34-13.8,13.8h0Z"
            stroke-width="0"
          />
        </svg>

        <h1>Marketing That Doesn't Suck</h1>
        <h2>Our goal? Harness every corner of the internet.</h2>
      </div>

      <div className="good-ad">
        <h3>Good Advertising is: Messaging, Media and Timing</h3>
        <p>
          At Freelance Jordan, it’s all about cutting through the noise. Great
          campaigns are built on sharp messaging, smart media, and impeccable
          timing. Whether it’s grabbing attention with killer ads, driving foot
          traffic, or using account&mdash;based strategies that actually work, I
          make sure your brand’s story gets told and your goals are
          met&mdash;without wasting a dime.
          <br />
          <br />
          People need you. Lets find them together.
        </p>
        <h3>Instant Gratification is What We're Here For</h3>
        <p>
          We all love instant gratification and when it comes to marketing what
          we're doing should just work. If not we're doing it wrong.
        </p>
        <div className="button-row">
          <button>
            <a
              href="https://www.instagram.com/freelancejordan/"
              target="_blank"
            >
              #killercampaign
            </a>
          </button>{" "}
          <Link to="/contact">
            <button>Lets Go!</button>
          </Link>
        </div>
      </div>

      <div className="plans-that">
        <h1>We Make Plans That Pan</h1>
        <h2>Don't just throw money at ads. Please?</h2>

        <h3>Account Based Marketing&mdash;ABM</h3>
        <p>
          Strategy is everything and so is getting the most out of your money
          and here we don’t like to see anything go to waste which is why we
          have elaborate schemes on schemes to directly target the people who
          need you then stay top of mind with them until they’re ready to dive
          in.
        </p>

        <h3>
          Foot traffic Campaigns that will have you begging us to turn them off
        </h3>
        <p>
          Believe it when you see it. These foot traffic campaigns are on point
          between copy writing and top notch media. The biggest factor is ease.
          Lets make it so easy for them to get to your shop, to get what you’re
          selling, they won’t be able to say no. In today’s economy, people need
          a little push to get out the door.{" "}
        </p>
      </div>

      <div className="how-i-parent">
        <div className="how-i">
          <svg
            id="uuid-5e7faca2-4604-4842-b2ec-778ddc702599"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1134.09 191.46"
          >
            <path
              d="m16.56,17.37c-.66,42.23-6.04,84.06-16.12,125.08-1.87,7.6,2.43,15.72,10.13,17.84s15.96-2.49,17.84-10.13c10.67-43.42,16.45-88.08,17.16-132.79.12-7.84-6.75-14.5-14.5-14.5s-14.38,6.64-14.5,14.5h0Z"
              stroke-width="0"
            />
            <path
              d="m30.62,109.69c17.44-5.69,34.48-12.5,51.12-20.21,7.1-3.29,8.89-13.54,5.2-19.84-4.26-7.29-12.71-8.51-19.84-5.2-14.38,6.67-29.13,12.37-44.2,17.29-7.44,2.43-12.27,10.03-10.13,17.84,2,7.3,10.36,12.57,17.84,10.13h0Z"
              stroke-width="0"
            />
            <path
              d="m66.82,10.57c-3.85,28.55-6.53,57.2-7.57,85.99-.5,13.74-.66,27.49-.47,41.23.11,8.47,2.16,16.51,9.8,21.36s19.08,3.27,22.97-5.59c3.15-7.17,2.1-15.57-5.2-19.84-6.15-3.6-16.67-2.01-19.84,5.2l-.36.82,5.2-5.2-.54.39,7.32-1.98,8.83,4.66.51,1.34.33.8-.03-1.96c.09-2.61-.06-5.24-.07-7.85-.02-5.56.01-11.13.1-16.69.17-10.8.56-21.59,1.15-32.38,1.15-20.92,3.02-41.83,5.82-62.59,1.05-7.75-1.86-15.57-10.13-17.84-6.75-1.86-16.78,2.32-17.84,10.13h0Z"
              stroke-width="0"
            />
            <path
              d="m140.9,61.49c6.63-5.8,6.39-16.66.47-22.87-6.73-7.05-17.25-6.66-24.37-.4s-12.78,15.96-16.7,24.68c-8.87,19.74-11.88,42.37-6.4,63.52,2.94,11.36,9.12,22.22,19.17,28.69,8.89,5.73,19.83,6.8,30,4.56,22.2-4.9,35.89-26.89,34.54-48.88-1.66-27.11-20.06-46.69-41.25-61.51-6.42-4.49-16.11-1.17-19.84,5.2-4.23,7.23-1.23,15.34,5.2,19.84,5.43,3.8,9.78,7.26,14.77,12.5,2.09,2.2,3.91,4.3,4.97,5.92s2.08,3.19,3.01,4.86c.4.72.77,1.45,1.15,2.17.15.29.98,2.23.45.9,1.17,2.93,2.05,6.11,2.51,9.23-.26-1.74.08,1.94.09,2.47.03,1.06-.03,2.1-.04,3.16,0,.56-.22,2.4.01.83-.23,1.55-.69,3.04-1.07,4.56.03-.1-.79,2.31-.34,1.19s-.59,1.19-.55,1.12c-.71,1.3-1.44,2.63-2.35,3.81,1.55-1.99-1.79,1.51-2.3,1.94,1.73-1.46-.79.43-1.46.81-.73.42-3.58,1.57-1.39.81-1.27.44-2.55.75-3.83,1.12-.22.06-2.35.35-.68.16s-1.05-.02-1.34-.02c-.44,0-3.17-.17-1.31.04,1.69.19-.39-.09-.63-.17-.91-.3-3.48-1.41-1.41-.31-.48-.25-3.04-1.99-1.72-.9-.57-.47-1.07-1.03-1.6-1.55-1.67-1.62.83,1.36-.54-.53-2.97-4.1-4.72-10.23-5.46-15.38-.62-4.33-.6-13.82,0-18.17,0,.06.56-3.15.81-4.3.44-2.06.95-4.1,1.55-6.13.52-1.77,1.1-3.52,1.73-5.25.86-2.35.42-1.36,1.25-3.1,1.59-3.33,3.32-6.57,5.32-9.68.57-.89,1.18-1.74,1.76-2.62.15-.23,1.41-1.98.56-.86.48-.63.99-1.24,1.5-1.83.8-.94,2.46-1.66.54-.68l-7.32,1.98-1.74-.31-6.4-3.73.65.76-3.73-6.4.31.92v-7.71l-.13.86,3.73-6.4-.65.57c-5.89,5.16-5.28,15.23,0,20.51,5.85,5.85,14.6,5.17,20.51,0h0Z"
              stroke-width="0"
            />
            <path
              d="m167.74,66.27c-.06,13.2,1.54,26.53,4.85,39.32,2.75,10.64,6.55,21.53,13.21,30.38,7.49,9.95,18.47,17.25,31.39,16.66,12.89-.59,24.44-7.82,32.35-17.72,3.54-4.43,6.59-9.09,9.43-13.99,1.38-2.37,2.84-4.74,4.12-7.16,1.39-2.61.63-1.3.38-.79,1.31-1.19,1.44-1.45.4-.77-1.59.63-3.19.41-4.81-.67l-3.84-.95c1.19,1.34,1.35,1.49.5.47.58.73,1.1,1.51,1.65,2.27,1.41,1.94,2.82,3.87,4.27,5.76,3.84,5.02,7.69,9.47,12.84,13.2,22.35,16.21,46.84-3.82,55.05-25.15,7.95-20.64,2.52-46.97-14.4-61.58-5.92-5.12-14.68-5.82-20.51,0-5.23,5.23-5.95,15.37,0,20.51,1.29,1.11,2.43,2.33,3.65,3.52,1.85,1.81.5.56.1,0,.49.68.96,1.37,1.4,2.08s.86,1.43,1.26,2.17c-.03-.05,1.7,3.64.88,1.67-.7-1.66.27.81.39,1.21.31,1.01.64,2.01.9,3.03.22.84.93,5.53.61,2.38.17,1.69.27,3.38.27,5.08s-.1,3.39-.3,5.07c.37-3.12-.65,2.47-.98,3.59-.23.77-2.06,5.09-.75,2.43-.73,1.51-1.51,2.98-2.41,4.4-.45.7-.92,1.4-1.41,2.07,1.6-2.15.28-.39-.12-.01-.61.58-1.16,1.23-1.76,1.81-.06.06-2.87,2.24-1.46,1.28-.81.56-1.79,1.2-2.68,1.57,1.78-.73-.7.1-.87.06,2.76.53-.28-.83-.3-.28l1.36.09-1.2-.37c.32-.91,1.76,1.59.06-.17s-.78-.52-.22-.04c-1.76-1.5-3.28-3.22-4.81-4.94-.21-.23-1.73-2.1-.41-.43-.59-.75-1.15-1.54-1.72-2.31-1.12-1.52-2.2-3.06-3.32-4.58-4.07-5.51-8.28-10.35-15.24-12.09-13.28-3.32-23.18,7.31-29.13,17.65-2.2,3.82-4.33,7.68-6.76,11.35-1.78,2.68-4.5,5.99-7.03,7.82,1.3-.93-2.97,1.7-2.49,1.42-.23.13-2.49.99-.8.4-.94.33-1.99.63-2.96.8.98-.17,1.64.19-.69-.09,2.67.32-3.96-1.27-.83.07-.81-.34-1.54-.85-2.31-1.27-2.19-1.18.29.45-.73-.58-.64-.64-1.27-1.28-1.9-1.92-1-1.03.65,1.23-.72-.86-1.3-1.97-2.5-3.98-3.55-6.09-.42-.84-.83-1.7-1.2-2.57,1.05,2.45-.55-1.64-.59-1.74-.95-2.66-1.79-5.37-2.55-8.09-3.24-11.49-4.6-22.42-4.55-34.34.03-7.84-6.69-14.5-14.5-14.5s-14.47,6.64-14.5,14.5h0Z"
              stroke-width="0"
            />
            <path
              d="m446.81,16.12c-12.81,37.62-22.59,76.23-29.09,115.43-.78,4.7-1.51,9.41-2.2,14.13-.77,5.26-2.1,10.8-2.09,16.11.03,11.7,11.59,19.7,22.51,18.49,12.75-1.42,20.43-15.17,14.41-26.73-5.03-9.65-18.67-15.18-28.19-8.25-6.32,4.61-9.44,12.6-5.2,19.84,3.65,6.24,13.48,9.84,19.84,5.2-3.13,2.28-10.32.44-11.9-3.09,2.05-5.11,4.09-10.22,6.14-15.33l5-.04c1.9.45,8.65,6.87,6.4,8.98.33-.31.31-2.3.37-2.76.91-6.61,1.93-13.21,3.03-19.79,2.15-12.85,4.65-25.64,7.49-38.36,5.75-25.75,12.94-51.16,21.45-76.13,2.52-7.41-2.9-15.85-10.13-17.84-7.94-2.18-15.3,2.69-17.84,10.13h0Z"
              stroke-width="0"
            />
            <path
              d="m407.54,59.07c45.04-11.31,90.77-18.18,137.13-20.49,7.82-.39,14.5-6.39,14.5-14.5,0-7.55-6.64-14.89-14.5-14.5-48.82,2.43-97.43,9.62-144.84,21.53-7.6,1.91-12.17,10.41-10.13,17.84,2.12,7.71,10.22,12.04,17.84,10.13h0Z"
              stroke-width="0"
            />
            <path
              d="m397.24,151.9c34.31,6.47,69.87,4.16,102.71-7.97,7.34-2.71,12.33-9.83,10.13-17.84-1.94-7.07-10.45-12.86-17.84-10.13-28.18,10.41-58.28,13.45-87.29,7.97-7.69-1.45-15.64,2.14-17.84,10.13-1.94,7.08,2.39,16.38,10.13,17.84h0Z"
              stroke-width="0"
            />
            <path
              d="m595.31,42.01c-15.93,37.09-20.52,77.91-11.58,117.45,1.92,8.51,5.19,17.23,11.49,23.5,7.71,7.67,19.4,10.47,29.76,7.08,18.06-5.91,31.08-24.05,39.21-40.4,9.44-18.99,14.18-40.21,12.8-61.43l-28.48,3.85c2.43,15.44,9.46,31.49,21.65,41.71,12.91,10.83,31.15,16.59,47.65,11.06,30.75-10.29,41.89-47.03,36.35-76.19-2.87-15.12-11.02-29.76-22.66-39.86-5.91-5.13-14.69-5.81-20.51,0-5.24,5.24-5.94,15.35,0,20.51,2.39,2.08,4.61,4.3,6.63,6.74-1.77-2.12,1.39,2.06,1.68,2.52.72,1.14,1.41,2.3,2.05,3.49.25.46,2.53,5.32,1.41,2.64.52,1.25.97,2.53,1.39,3.81.99,3.02,1.68,6.09,2.27,9.2.27,1.73.28,1.79.03.18.09.9.16,1.8.22,2.7.08,1.35.12,2.71.11,4.06-.01,1.81-.15,3.6-.27,5.41-.22,3.42.48-1.56-.23,1.5s-1.5,6.08-2.48,9.1c-.62,1.59-.64,1.68-.06.28-.37.82-.76,1.63-1.17,2.43-.72,1.41-1.51,2.78-2.36,4.11-1.96,3.08-2.42,3.62-5.5,6.46-.75.63-.76.68-.02.17-.82.6-1.67,1.17-2.54,1.69-.88.52-1.78,1-2.7,1.43.88-.41.86-.45-.08-.11-.61.18-5.15.91-2.79.73-1.75.14-3.54.12-5.29-.06-1.04-.09-1.01-.09.09-.01-1-.2-1.99-.44-2.97-.72-.98-.28-1.96-.6-2.92-.96.86.38.84.36-.06-.07-3.51-1.7-5.31-3.09-7.1-4.85-2.68-2.61-3.32-3.39-5.45-7.01-3.34-5.69-5.19-12.02-6.41-19.79-1.1-7.02-9.5-11.35-15.91-10.39s-13.05,6.93-12.57,14.24c.95,14.66-.61,25.84-5.81,39.86-3.83,10.35-11.95,24.63-21,31.62-.17.13-2.25,1.46-3.16,1.98-1.37.79-.42.33-.04.17-.85.36-1.99.69-2.87.83,1.39-.22.46.15.22-.19.04.06-1.12-.59.23-.02.68.29,1.25.12.27.07-1.63-.08,1.25.71-.1-.09-1.64-.97.89,1.55-.18-.08-.36-.55-.66-1.15-1.04-1.7-1.38-2.01.05.84-.74-1.5-1.11-3.28-1.92-6.62-2.63-10-2.94-14.12-3.7-29.18-2.52-44.42,1.2-15.44,5.52-33.61,11.74-48.1,3.09-7.19,2.14-15.55-5.2-19.84-6.11-3.57-16.73-2.03-19.84,5.2h0Z"
              stroke-width="0"
            />
            <path
              d="m764.61,43.01c-6.13,15.44-8.52,32.78-6.73,49.31,1.83,16.85,6.64,34.59,17.09,48.23,6.07,7.92,14.04,13.64,24.18,14.63,8.11.8,16.2-2.23,22.09-7.82,10.91-10.35,13.9-26.92,11-41.18-3.76-18.48-16.52-34.77-32.46-44.4-6.7-4.05-15.93-1.49-19.84,5.2s-1.52,15.78,5.2,19.84c4.55,2.75,9.99,7.75,12.71,11.85.97,1.47,1.88,2.98,2.71,4.54.47.89.92,1.79,1.35,2.7-.44-1.16-.35-.92.28.72.5,1.43.98,2.86,1.38,4.32.24.88.9,6.31.72,2.63.06,1.23.14,2.44.11,3.67-.02.82-.07,1.65-.15,2.47.27-1.8.25-1.87-.04-.18-.33,1.16-.66,2.38-.95,3.55-.58,1.56-.53,1.52.13-.12-.33.7-.7,1.39-1.09,2.07-1.16,2.71,1.55-1.13-.39.69-.49.53-1.01,1.04-1.56,1.5,1.54-1.16,1.65-1.32.31-.47,1.96-1.8,1.11-.4-.54-.07,2.03-.26,2.19-.38.48-.34,1.47.16,1.36.03-.34-.38,1.33.46,1.23.29-.3-.51.83.65.87.63.12-.03-.42-.4-3.16-3.71-2.09-2.21-2.12-2.96-3.78-6.3-5.28-9.6-.93-2.04-2.18-5.64-3.14-8.94-1.08-3.71-1.92-7.48-2.54-11.3-.29-1.82-.34-2.18-.15-1.07-.12-1.1-.22-2.2-.3-3.3-.12-1.65-.2-3.31-.24-4.96-.09-3.87.11-7.72.44-11.57.21-1.84.25-2.19.1-1.07.17-1.09.35-2.18.56-3.26.31-1.63.66-3.24,1.05-4.85,1.05-4.3,2.45-8.48,4.08-12.58,2.89-7.28-3.16-15.92-10.13-17.84-8.25-2.27-14.93,2.82-17.84,10.13h0Z"
              stroke-width="0"
            />
            <path
              d="m843.63,90.06c14.64,20.61,25.64,42.54,32.98,66.34,8.83-3.72,17.67-7.45,26.5-11.17-10.89-15.24-18.22-31.29-20.94-49.94-.44-3.03-.82-8.49-.2-12.37,0,0,.83-3.03,1.13-4.03.82-2.73-.83,1.44.24-.73.54-1.09,1.15-2.14,1.82-3.16-1.06,1.63.14-.23.41-.52.66-.7,1.33-1.4,2.05-2.05-1.5,1.34.12-.11.5-.32.78-.45,1.64-1.02,2.5-1.36-2.29.88.33.05.55.07-.55-.07-2.57-.27.34.03-.64-.06-2.64-.2.22.03l1.32.4c-1.12-.5-1.23-.51-.32-.03,1.96.79-1.17-1.25.41.13.27.23,2.27,2.24,1.13,1.05s.49,1.98.12.25c-.43-1.98.29,2.34-.03.17l-.13-.6c.28-1.33.29-1.48.03-.45.29.46-.3.91.26-.27-.38.81-.9,1.62-1.41,2.36,1.39-2.04-1.23,1.25-1.38,1.4-6.19,6.37-13.24,11.37-17.22,19.63-5.34,11.07-4.23,24.07,5.21,32.6,7.44,6.72,17.5,11.08,26.37,15.59,20.9,10.63,42.67,19.62,64.94,26.95,7.44,2.45,15.83-2.84,17.84-10.13,2.17-7.88-2.66-15.38-10.13-17.84-20.93-6.89-40.9-15.18-60.48-25.29-4.66-2.41-9.28-4.89-13.84-7.47-.96-.54-1.94-1.08-2.89-1.65.29.18-3.51-2.49-2.18-1.37,1.11.94-1.02-1.88-.37-.53.39.81.74,1.79,1.08,2.58.5,1.16,1.03-1.9.17-.18.45-.9,1.05-1.85,1.63-2.65-.44.59-1.19,1.18.71-.73.73-.73,1.44-1.46,2.19-2.16,3.36-3.12,6.92-5.98,9.86-9.52,8.16-9.81,11.59-22.85,5.04-34.58-5.5-9.86-16.76-16.81-28.15-16.7-25.24.24-39.56,25.47-38.75,48.26.88,24.68,11.09,49.83,25.32,69.75,4.19,5.87,11.11,9.01,18.11,5.93,6.39-2.81,10.57-10.07,8.4-17.11-8.06-26.12-20.07-50.97-35.9-73.26-4.53-6.38-12.65-9.4-19.84-5.2-6.3,3.69-9.76,13.42-5.2,19.84h0Z"
              stroke-width="0"
            />
            <path
              d="m973.01,43.48c3.88,38.18,10.92,75.94,21.48,112.84,3.95,13.79,24.01,13.8,27.96,0,9.55-33.35,24.57-64.03,45.08-92.49,3.71-5.15,1.97-13.34-2.27-17.57-4.15-4.15-12.55-6.13-17.57-2.27-12.92,9.94-25.71,20.12-31.96,35.7-5.78,14.38-4.28,32.26,6.8,43.77s29.74,14.93,44.75,18.9c16,4.23,32.2,7.68,48.52,10.42,7.71,1.29,15.61-2.03,17.84-10.13,1.91-6.95-2.36-16.53-10.13-17.84-13.5-2.27-26.91-5.01-40.2-8.29-6.49-1.6-12.95-3.33-19.37-5.18-5.29-1.52-12.8-3.46-16.18-5.18-1.17-.6-2.35-1.2-3.42-1.96-.82-.53-.88-.58-.17-.13l-1.12-1.11c1.44,1.95-.91-1.54-.98-1.67.95,1.74-.05-.19-.29-1.1-.26-.96-.47-3.11-.32-1.03-.1-1.45-.07-2.87-.01-4.32-.08,2.05.02-.28.23-1.19.27-1.19.63-2.35.98-3.52,1.01-3.28-.97,1.01.86-1.85.73-1.15,1.38-2.35,2.13-3.49.55-.85,2.82-3.53,4.5-5.23,1.93-1.96,3.97-3.8,6.07-5.58,1.84-1.56,5.28-4.29,6.13-4.94-6.61-6.61-13.23-13.23-19.84-19.84-21.63,30.01-37.83,63.85-48.01,99.41h27.96c-9.85-34.41-16.84-69.67-20.44-105.13-.79-7.79-6.1-14.5-14.5-14.5-7.23,0-15.3,6.66-14.5,14.5h0Z"
              stroke-width="0"
            />
          </svg>

          <h2>Your Dreams. My Responsibility.</h2>
        </div>
      </div>

      <div className="how-i-body">
        <h3>Too many times I’ve heard the story...</h3>
        <p>
          You paid that online marketing company $1600?? For what? Some Google
          ads for A MONTH?!
          <br />
          <br />
          This is my typical dialogue when I’m poaching clients from big ad
          agencies. They have little to no accountability and big price tags on
          services that are not that comprehensive and have very little
          gaurantees of success.
          <br />
          <br />
          With me you get a real person&mdash;with real things I want. And the
          biggest thing I want is to see you succeed. It gives me all the good
          vibes and I'm here for it. The big wins, the small wins and everything
          in between.
        </p>

        <h3>When I’m hired&mdash;I don’t stop till the job is done</h3>
        <p>
          That means those ten clients you’ve been dreaming of. They’re yours.
          The end of slow days for your shop? What would that mean for you? It
          means less hustling and more being able to do what you actually got
          into this business to be doing. It means scaling, it means growing
          into that space you always wanted to be. It means being appreciated.
          Because people need you&mdash;they really do&mdash;sometimes it’s just
          hard to find them.
        </p>

        <div className="button-row">
          <Link to="/aboutme">
            {" "}
            <button>That's where I come in.</button>
          </Link>{" "}
          <Link to="/contact">
            <button>Lets Talk.</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
